import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Axios from "axios";
import store from "./store";

Vue.config.productionTip = false;

Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.baseURL = process.env.BASE_URL;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
