<template>
  <div>
    <br />
    <b-container>
      <p class="text-right">
        <b-button variant="primary" href="/guest">add guest</b-button>
      </p>
      <b-alert
        :show="dismissCountDown"
        dismissible
        class="mt-3"
        variant="success"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
        >Successfully Saved Guest</b-alert
      >
      <b-card class="mb-3" header="Guest Detail Form">
        <b-form v-if="show" class="mb-3">
          <b-row class="my-1">
            <b-col sm="3" align="right"> First Name: </b-col>
            <b-col sm="9">
              <b-form-input type="text" v-model="formData.first_name">
                ></b-form-input
              >
            </b-col>
          </b-row>
          <b-row class="my-1" align="right">
            <b-col sm="3"> Last Name: </b-col>
            <b-col sm="9">
              <b-form-input type="text" v-model="formData.last_name">
                ></b-form-input
              >
            </b-col>
          </b-row>
          <b-row class="my-1" align="right">
            <b-col sm="3"> Date of Birth: </b-col>
            <b-col sm="9">
              <b-form-input
                type="date"
                v-model="formData.date_of_birth"
                locale="en"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1" align="right">
            <b-col sm="3"> Social Security Number: </b-col>
            <b-col sm="9">
              <b-form-input type="text" v-model="formData.social_security">
                ></b-form-input
              >
            </b-col>
          </b-row>
          <b-row class="my-1" align="right">
            <b-col sm="3"> Driver License Number: </b-col>
            <b-col sm="9">
              <b-form-input type="text" v-model="formData.drivers_license">
                ></b-form-input
              >
            </b-col>
          </b-row>
          <b-row class="my-1" align="right">
            <b-col sm="3"> Driver License State: </b-col>
            <b-col sm="9">
              <b-form-input
                type="text"
                v-model="formData.drivers_license_state"
              >
                ></b-form-input
              >
            </b-col>
          </b-row>
          <b-row class="my-1" align="right">
            <b-col sm="3"> Phone Number: </b-col>
            <b-col sm="9">
              <b-form-input type="phone_number" v-model="formData.phone_number">
                ></b-form-input
              >
            </b-col>
          </b-row>
          <b-row class="my-1" align="right">
            <b-col sm="3"> Sex Offender: </b-col>
            <b-col sm="9" align="left">
              <b-form-checkbox
                type="checkbox"
                value="true"
                unchecked-value="false"
                v-model="formData.sex_offender"
              >
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="my-1" align="right">
            <b-col sm="3"> Vehicle: </b-col>
            <b-col sm="9" align="left">
              <b-form-checkbox
                type="checkbox"
                v-model="formData.vehicle"
                value="true"
                unchecked-value="false"
              >
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="my-1" align="right">
            <b-col sm="3"> Vehicle Make: </b-col>
            <b-col sm="9">
              <b-form-input type="text" v-model="formData.vehicle_make">
                ></b-form-input
              >
            </b-col>
          </b-row>
          <b-row class="my-1" align="right">
            <b-col sm="3"> Veteran: </b-col>
            <b-col sm="9" align="left">
              <b-form-checkbox
                type="checkbox"
                v-model="formData.veteran"
                value="true"
                unchecked-value="false"
              >
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="my-1" align="right">
            <b-col sm="3"> Parolee: </b-col>
            <b-col sm="9" align="left">
              <b-form-checkbox
                type="checkbox"
                v-model="formData.parolee"
                value="true"
                unchecked-value="false"
              >
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="my-1" align="right">
            <b-col sm="3"> Race: </b-col>
            <b-col sm="9" align="left">
              <select id="race" v-model="formData.race_demographic">
                <option
                  v-for="(label, value) in raceOptions"
                  :key="value"
                  :value="value"
                >
                  {{ label }}
                </option>
              </select>
            </b-col>
          </b-row>
          <b-row class="my-1" align="right">
            <b-col sm="3"> Income: </b-col>
            <b-col sm="9" align="left">
              <select id="race" v-model="formData.income_range">
                <option
                  v-for="(label, value) in incomeOptions"
                  :key="value"
                  :value="value"
                >
                  {{ label }}
                </option>
              </select>
            </b-col>
          </b-row>
          <div>
            <b-button-group>
              <b-button type="reset" variant="danger" style="padding: 5px"
                >Reset</b-button
              >
              <b-button
                v-if="isEmptyObject() === true"
                @click.prevent="createGuest()"
                variant="primary"
                style="padding: 5px"
                >Create</b-button
              >
              <b-button
                v-else
                @click.prevent="onSubmit()"
                variant="primary"
                style="padding: 5px"
                >Update</b-button
              >
            </b-button-group>
          </div>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "GuestForm",
  data() {
    return {
      show: true,
      submitted: false,
      newGuest: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      raceOptions: {
        ASIAN: "Asian",
        BLACK: "Black",
        HISPANIC: "Hispanic",
        WHITE: "White",
        MULTIRACE: "Multirace",
        OTHER: "Other",
      },
      incomeOptions: {
        LOW: "$0",
        LOW_MID: "$16,050 (30%)",
        MID: "$26,750 (50%)",
        HIGH: "$42,750 (80%)",
        VERY_HIGH: "64,100 (120%)",
      },
    };
  },
  props: {
    guest: Object,
  },
  computed: {
    formData: function () {
      return this.guest || {};
    },
  },
  methods: {
    async onSubmit() {
      const response = await axios.patch(
        "/api/hhms/guests/" + this.formData.id + "/",
        this.formData
      );
      this.formData = response.data;
      // Show success popup
      alert("Form submitted successfully!");
    },
    async createGuest() {
      const response = await axios.post("/api/hhms/guests/", this.formData);
      this.formData = response.data;
      console.log("status:" + response.status);
      if (response.status === 201) {
        this.showAlert();
      }
    },
    isEmptyObject() {
      if (typeof this.guest === "undefined" || this.guest === null) {
        return true;
      } else {
        console.log("initialized");
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>

<style scoped>
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-color: #ddd;
}

.panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;

  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-body {
  padding: 15px;
}

.p {
  padding: 5px;
}
</style>
