<template>
  <b-container>
    <h1>Bookings</h1>
    <div>
      <b-row>
        <b-col cols="3">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="bookings_table"
          ></b-pagination>
        </b-col>
        <b-col cols="6">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Type to filter data"
          ></b-form-input>
        </b-col>
        <b-col cols="3">
          <p class="text-right">
            <b-button variant="primary" href="/booking"
              >add new booking</b-button
            >
          </p>
        </b-col>
      </b-row>
      <b-table
        @row-clicked="bookingDetails"
        striped
        hover
        :items="bookings"
        :fields="fields"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
      ></b-table>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
export default {
  name: "Bookings",
  data() {
    return {
      filter: null,
      bookings: null,
      perPage: 20,
      currentPage: 1,
      sortBy: "check_out",
      sortDesc: true,
      rows: null,
      fields: [
        { key: "id", sortable: true },
        { key: "guest_name", sortable: true, label: "Guest Name" },
        { key: "check_in", sortable: true },
        { key: "check_out", sortable: true },
        { key: "booking_date", sortable: true },
        { key: "status", sortable: true },
        { key: "bed_name", sortable: true },
      ],
    };
  },
  async created() {
    await axios
      .get("/api/hhms/bookings/?current=True")
      .then((response) => (this.bookings = response.data));
    this.rows = this.bookings.length;
  },
  methods: {
    bookingDetails(booking) {
      if (booking.status === "CHECKEDIN") {
        this.$router.push({
          name: "Checkout",
          params: { bookingId: booking.id },
        });
      } else {
        this.$router.push({
          name: "BookingDetails",
          params: { bookingId: booking.id },
        });
      }
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped></style>
