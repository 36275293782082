<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <h1>Harmony House Bed Management System</h1>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-alert
        :show="showBirthday"
        variant="primary"
        dismissible
        @close="showBirthday = false"
      >
        <div v-for="(bday, index) in birthday" :key="index" class="alert-row">
          <strong
            >Happy Birthday {{ bday.first_name }} {{ bday.last_name }}!</strong
          >
        </div>
      </b-alert>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-card-group deck>
        <CheckoutsCard />
        <EmptyBedsCard />
        <DailyCensusCard />
      </b-card-group>
    </b-row>
  </b-container>
</template>

<script>
import CheckoutsCard from "@/components/CheckoutsCard";
import EmptyBedsCard from "@/components/EmptyBedsCard";
import DailyCensusCard from "@/components/DailyCensusCard";
import axios from "axios";
export default {
  name: "Home",
  components: { EmptyBedsCard, CheckoutsCard, DailyCensusCard },
  data() {
    return {
      showBirthday: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await axios
        .get("/api/hhms/guests/today_birthdays/")
        .then((response) => (this.birthday = response.data));
      if (this.birthday.length > 0) {
        this.showBirthday = true;
      }
    },
  },
};
</script>

<style scoped></style>
